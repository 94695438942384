/** @jsx jsx */
import { jsx } from "theme-ui"
import styled from "styled-components"

const ColorBlock = ({ offset }) => (
  <div style={{ backgroundColor: "#990066" }}>
    <div className="relative overflow-hidden">
      <div className="mx-auto p-6 md:pt-6 sm:p-8">
        <div className="md:flex p-2 justify-center ">
          <div className="flex flex-col w-full">
            <p className="text-center text-white"  style={{ fontSize: "22px"}}>
              "If we wait for the moment when everything, absolutely everything is ready, we shall never begin"
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default ColorBlock
