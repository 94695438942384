/** @jsx jsx */
import React from "react"

import { jsx } from "theme-ui"
import IllustrationA from "../../images/illustrations/hero-beingcircle.jpg"
import { Link } from "gatsby"

export default function Hero(offset) {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div
      className="hero image-as-background"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url(${IllustrationA})`,
      }}
    >
      <div className="relative overflow-hidden hero-gradient pt-12 pb-12">
        <div className="max-w-screen-xl mx-auto p-6 md:pt-12 sm:p-8">
          <div className="flex justify-center">
            <div className="mt-4">
               <h2
                    style={{ fontSize: 24, color: "#000"}}
                    className="uppercase text-center pb-12 tracking-tight leading-8 sm:leading-none font-bold text-3xl sm:text-3xl md:text-3xl lg:text-5xl"
                  >
                    Being &middot; Circle
                  </h2>
                  <h1 style={{color: "#000"}} className="huge-font text-white text-center tracking-tight leading-8 sm:leading-none font-bold">
                    JOIN
                  </h1>
                  <h2
                    style={{ color: "#990066"}}
                    className="text-center tracking-tight leading-8 sm:leading-none font-bold med-font text-3xl sm:text-3xl md:text-3xl lg:text-5xl"
                  >
                    INSPIRE
                  </h2>

                  <p className="uppercase text-center mt-3 sm:mt-5 text-xs sm:text-md md:text-lg font-bold" style={{ maxWidth: 800, fontSize: 20, color: "#000", }}>
                    Become a member &middot; Be a role model
                  </p>

              <div className="text-center mt-6 sm:mt-8 sm:flex sm:justify-center lg:justify-center">

                    <div className="">
                    <Link
                      to={"/pricing"}
                      className="cursor-pointer shadow bg-zone-cta focus:shadow-outline focus:outline-none text-white font-bold py-3 px-6 rounded"
                    >
                      Get Started
                    </Link>
                    </div>
                  </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
