/** @jsx jsx */
import React from "react"
import { jsx } from "theme-ui"
import Coachben from "../../images/illustrations/cobene-pics.png"
import styled from "styled-components"

export default class Schedule extends React.Component {
  state = {
    name: "",
    email: "",
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    })

    this.setState({
      mailToString:
        "/schedule-demo?name=" + this.state.name + "&email=" + this.state.email,
    })
  }

  render() {
    return (
      <div>
        <div className="relative overflow-hidden">
          <div className="mx-auto p-6 md:pt-12 sm:p-8">
          
            <div className="md:flex p-4 justify-center">
            <div className="ml-8 mr-8 flex flex-col w-full md:w-1/2 lg:w-1/2 xl:w-1/2 ">
                <img
             style={{ maxHeight: 400 }}
                    className="w-auto h-auto"
            src={Coachben}
            alt="Coaching Benefits"
            />

              </div>
            <div className="ml-8 mr-8 flex flex-col w-full md:w-1/2 lg:w-1/2 xl:w-1/2 ">
            <div className="" >
                    <h2
                      style={{
                        fontWeight: 800,
                        fontSize: 28,
                      }}
                      className=" mt-4 uppercase tracking-tight leading-8 sm:leading-none font-bold text-3xl sm:text-3xl md:text-3xl lg:text-5xl"
                    >
                      What is Coaching? 
                    </h2>

                    <p
                      style={{
                        fontSize: 14,
                      }}
                      className="mt-1 para"
                    >
                      
                  Coaching is a space to identify, understand, and recognize the strength within the self. Coaching brings awareness to the self and situation. The coach helps you guide and push when needed, enabling you to make decisions, focus, communicate and collaborate.

                    </p>
                    <p
                      style={{
                        fontSize: 14, fontWeight: "bold",
                      }}
                      className="mt-1 para"
                    >
                      
                  The study clearly shows well-being is directly proportional to performance.

                    </p>
                </div>
            </div>
              <div className="ml-8 mr-8 flex flex-col w-full md:w-1/2 lg:w-1/2 xl:w-1/2 ">
                  <div className="" >
                    <h2
                      style={{
                        fontWeight: 800,
                        fontSize: 28,
                      }}
                      className=" mt-4 uppercase tracking-tight leading-8 sm:leading-none font-bold text-3xl sm:text-3xl md:text-3xl lg:text-5xl"
                    >
                      Coaching Benefits
                    </h2>
                    <h2
                      style={{
                        fontWeight: 800,
                        fontSize: 18,
                      }}
                      className=" mt-4 uppercase tracking-tight leading-8 sm:leading-none font-bold text-3xl sm:text-3xl md:text-3xl lg:text-5xl"
                    >
                      Self-awareness
                    </h2>
                    <h2
                      style={{
                        fontWeight: 800,
                        fontSize: 18,
                      }}
                      className=" mt-4 uppercase tracking-tight leading-8 sm:leading-none font-bold text-3xl sm:text-3xl md:text-3xl lg:text-5xl"
                    >
                      Confidence and Courage
                    </h2>
                    <h2
                      style={{
                        fontWeight: 800,
                        fontSize: 18,
                      }}
                      className=" mt-4 uppercase tracking-tight leading-8 sm:leading-none font-bold text-3xl sm:text-3xl md:text-3xl lg:text-5xl"
                    >
                      Communication
                    </h2>
                    <h2
                      style={{
                        fontWeight: 800,
                        fontSize: 18,
                      }}
                      className=" mt-4 uppercase tracking-tight leading-8 sm:leading-none font-bold text-3xl sm:text-3xl md:text-3xl lg:text-5xl"
                    >
                      Strategic Planning
                    </h2>
                    <h2
                      style={{
                        fontWeight: 800,
                        fontSize: 18,
                      }}
                      className=" mt-4 uppercase tracking-tight leading-8 sm:leading-none font-bold text-3xl sm:text-3xl md:text-3xl lg:text-5xl"
                    >
                      Self-reliant
                    </h2>
                    <h2
                      style={{
                        fontWeight: 800,
                        fontSize: 18,
                      }}
                      className=" mt-4 uppercase tracking-tight leading-8 sm:leading-none font-bold text-3xl sm:text-3xl md:text-3xl lg:text-5xl"
                    >
                      Mental Wellness
                    </h2>
                    <h2
                      style={{
                        fontWeight: 800,
                        fontSize: 18,
                      }}
                      className=" mt-4 uppercase tracking-tight leading-8 sm:leading-none font-bold text-3xl sm:text-3xl md:text-3xl lg:text-5xl"
                    >
                      Work-life Balance
                    </h2>
                    <h2
                      style={{
                        fontWeight: 800,
                        fontSize: 18,
                      }}
                      className=" mt-4 uppercase tracking-tight leading-8 sm:leading-none font-bold text-3xl sm:text-3xl md:text-3xl lg:text-5xl"
                    >
                      Accountability
                    </h2>
                </div>
               </div>
            </div>

          </div>
        </div>
      </div>
    )
  }
}
