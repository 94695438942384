import React from "react"
import Layout from "../components/layout"
import Hero from "../components/being-circle/hero"
import Iquote from "../components/being-circle/quote"
import Icoachingben from "../components/being-circle/icoachingben"


export default function forIndividuals() {
  return (
    <Layout>
      <Hero />
      <Iquote />
      <Icoachingben />

    </Layout>
  )
}
